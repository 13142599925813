import { Script } from 'gatsby';

import React from 'react';

import { useTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { schemaHero } from '../../morphismSchema/templates/schemaHero';
import { schemaHeroMosaic } from '../../morphismSchema/templates/schemaHeroMosaic';

import morphism from 'morphism';
import { schemaHeroVideo } from '../../morphismSchema/templates/schemaHeroVideo';

import { Hero } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { HeroMosaic } from 'libs/growth-platform-brand-system-v2/src/templates/HeroMosaic';
import { HeroVideo } from 'libs/growth-platform-brand-system-v2/src/templates/HeroVideo';
import { HeroHome } from 'libs/growth-platform-brand-system-v2/src/templates/HeroHome';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';
import { TemplateBlocksZone } from '../TemplateBlocksZone';
import { schemaHeroHome } from '../../morphismSchema/templates/schemaHeroHome';

const TemplateZone = ({ doc, hero, pageContext }: any) => {
  const screenSize = useScreenSize();
  const { trackingPage } = useTracking();
  const useTrustpilot = hero?.trustpilotWidget?.length > 0;

  const _data = morphism(schemaBaseTemplate, {
    ...doc,
    screenSize,
    pageContext,
    trackingPage,
  });

  return (
    <div>
      {useTrustpilot && (
        <Script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" />
      )}
      {hero.__typename === 'DatoCmsHero' && (
        <Hero {...morphism(schemaHero, hero)} />
      )}
      {hero.__typename === 'DatoCmsHeroHome' && (
        <HeroHome {...morphism(schemaHeroHome, hero)} />
      )}
      {hero.__typename === 'DatoCmsHeroMosaic' && (
        <HeroMosaic {...morphism(schemaHeroMosaic, hero)} />
      )}
      {hero.__typename === 'DatoCmsHeroVideo' && (
        <HeroVideo {...morphism(schemaHeroVideo, hero)} />
      )}
      {_data?.blocks.length > 0 && <TemplateBlocksZone {..._data} />}
    </div>
  );
};

export default TemplateZone;
