import React from 'react';

export type StarProps = {
  color?: string;
  width?: number;
};
export const Star = ({ color = '#FFD23F', width = 14 }: StarProps) => {
  const height = width;
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width, height }}
    >
      <path
        d="M7.00016 11.013L11.1202 13.4997L10.0268 8.81301L13.6668 5.65967L8.8735 5.25301L7.00016 0.833008L5.12683 5.25301L0.333496 5.65967L3.9735 8.81301L2.88016 13.4997L7.00016 11.013Z"
        fill={color}
      />
    </svg>
  );
};
