import { fontBodyMini } from '../../styles/typographyNew';
import { classNames } from '../../utils/style';
import React, { ReactElement } from 'react';

export interface LogoListStaticProps {
  logos: ReactElement[];
  title: string;
  classname?: {
    parent?: string;
    logo?: string;
    logoContainer?: string;
    title?: string;
  };
}

export const LogoListStatic: React.FC<LogoListStaticProps> = ({
  logos,
  title,
  classname,
}) => {
  return (
    <div
      className={classNames('LogoListStatic text-center', classname?.parent)}
    >
      <div
        className={classNames(
          'flex flex-wrap justify-center items-center gap-y-24 gap-x-32 lg:gap-x-40 lg:gap-y-32',
          'max-w-[400px] lg:max-w-full',
          'm-auto',
          classname?.logoContainer,
        )}
      >
        {logos?.map((logo, index) => (
          <div
            key={index}
            className={classNames(
              'lg:w-auto flex justify-center mb-4 lg:mb-0 w-fit',
              'h-20 lg:h-24',
              classname?.logo,
            )}
          >
            {logo}
          </div>
        ))}
      </div>
      <p
        className={classNames(
          fontBodyMini,
          'mt-[32px] text-neutral-101',
          classname?.title,
        )}
      >
        {title}
      </p>
    </div>
  );
};
