import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { Query } from '../../../types/dato';
import TemplateZone from '../../components/TemplateZone';
import Layout from '../../layout';
import {
  HeadWrapper,
  largestContentfullPaintImg,
} from '../../layout/HeadWrapper';

export const Head = ({ data, pageContext, location }: any) => {
  const heroProps = data?.datoCmsGenericPage?.hero?.[0];
  const largestContentfullPaintImg: largestContentfullPaintImg =
    heroProps?.vimeoImage?.gatsbyImageData?.images?.fallback ||
    heroProps?.image?.gatsbyImageData?.images?.fallback ||
    null;

  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="GenericPage"
      dataName={'datoCmsGenericPage'}
      largestContentfullPaintImg={largestContentfullPaintImg}
    />
  );
};

export const query = graphql`
  query DatoGenericPage($locale: String, $id: String) {
    datoCmsGenericPage(locale: $locale, id: { eq: $id }) {
      ...GenericPage
    }
  }
`;

const GenericPage = ({ data, pageContext }: PageProps<Query>) => {
  const doc = data.datoCmsGenericPage;

  if (!doc) return <></>;
  const hero = doc.hero?.[0];
  const blocks = doc.blocks;

  return (
    <Layout pageContext={pageContext} data={doc} templateName="GenericPage">
      <TemplateZone
        doc={doc}
        hero={hero}
        blocks={blocks}
        pageContext={pageContext}
      />
    </Layout>
  );
};

export default GenericPage;
