import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { HeroHomeProps } from 'libs/growth-platform-brand-system-v2/src/templates/HeroHome';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { createSchema, morphism } from 'morphism';
import React from 'react';

import {
  filterIsSelfServeButMobile,
  schemaDatoButton,
} from '../schemaDatoButton';
import { schemaImage } from '../components/schemaImage';
import { Image } from 'libs/growth-platform-brand-system-v2/src/components/Image';

export const schemaHeroHome = createSchema<HeroHomeProps, any>({
  buttons: {
    path: ['buttons', 'locale'],
    fn: ({ buttons, locale }: any) => {
      if (buttons) {
        const btns = filterIsSelfServeButMobile(buttons);
        return btns
          ?.map((btn, key) =>
            morphism(schemaDatoButton, {
              ...btn,
              locale,
            }),
          )
          .map((btn, key) => (
            <ButtonTracking
              component={ButtonV2}
              {...btn}
              index={key}
              key={key}
              labelTracking={btn.title}
              className="w-full"
              section="hero"
            />
          ));
      }
      return;
    },
  },
  title: 'title',
  subtitle: 'subtitle',
  rating: 'rating',
  ratingText: 'ratingText',
  productTour: 'productTour',
  logos: {
    path: ['logoList'],
    fn: ({ logoList }: any) => {
      return logoList?.[0]?.logos?.map((logo: any, index: number) => {
        return <Image key={index} {...morphism(schemaImage, logo)} />;
      });
    },
  },
  logoListTitle: {
    path: ['logoList'],
    fn: ({ logoList }: any) => {
      return logoList?.[0]?.title;
    },
  },
});
