import React from 'react';
import { Star } from './Star';
import { classNames } from '../../utils/style';

export type RatingProps = {
  rating: number;
  className?: string;
  widthStar?: number;
  gap?: number;
};
export const Rating = ({
  rating = 4,
  className,
  widthStar = 16,
  gap = 2.5,
}: RatingProps) => {
  return (
    <div
      className={classNames(
        'Rating',
        'w-fit inline-flex items-center',
        className,
      )}
      style={{ gap }}
    >
      {[1, 2, 3, 4, 5].map((i, key) => {
        const mathFloor = Math.floor(rating);
        let calcWidthStart = i <= mathFloor ? 100 : 0;

        if (i === mathFloor + 1) {
          calcWidthStart = (rating - mathFloor) * 100;
        }
        return (
          <span key={key} className="relative">
            <Star color="#DFE6ED" width={widthStar} />
            <span
              className="absolute top-0 left-0 overflow-hidden"
              style={{ width: `${calcWidthStart}%` }}
            >
              <Star width={widthStar} />
            </span>
          </span>
        );
      })}
    </div>
  );
};
